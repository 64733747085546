import React from 'react';

interface TitleProps {
  title?: string
  subtitle?: string
  sal?: string
  delay?: string
  duration?: string
  easing?: string
  centered: boolean
}

const Title = ({
  maintitle,
  subtitle,
  sal,
  delay,
  duration,
  easing,
  centered
} : TitleProps) => {

  let centerTitle = "";

  if (centered)  {
    centerTitle = "centered"
  }

  return (
    <div className={`headline ${centerTitle}`} data-sal={sal} data-sal-duration={duration} data-sal-delay={delay} data-sal-easing={easing}>
      <h6>{subtitle}</h6>
      <h2>{maintitle}</h2>
    </div>
  )
};

export default Title;
